<template>
  <section class="section">
    <h2 class="title">
      {{ $t('route.alerts') }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t('subtitle.alerts') }}
        </div>
        <div class="level-right">
          <button class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /> <span>{{ $t('newAlert') }}</span>
          </button>
        </div>
      </div>
    </h2>
    <box>
      <data-table
        ref="table" :items="items" sort-by="_id"
        :theme="$_ui_theme_tables"
      >
        <data-column field="title" :label="$t('title')" width="15%" />
        <data-column field="description" :label="$t('description')" />
        <data-column field="creator.name" :label="$t('creator')" width="15%" />
        <data-column field="last" :label="$t('lastAlert')" width="15%">
          <template slot-scope="props">
            {{ props.value ? format(new Date(props.value), 'dd/MM/yyyy HH:mm:ss') : 'None' }}
          </template>
        </data-column>
        <data-column field="enabled" :label="$t('status')" width="10%">
          <template slot-scope="{ value }">
            <span v-if="value" class="tag is-success is-light">{{ $t('enabled') }}</span>
            <span v-else class="tag is-warning is-light">{{ $t('disabled') }}</span>
          </template>
        </data-column>
        <data-column
          :label="$t('actions')" class="has-text-centered" :sortable="false"
          width="15%"
        >
          <template slot-scope="props">
            <div class="has-text-centered">
              <button class="button is-text" @click.prevent="edit(props.item)">
                <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
              </button>
              <button class="button is-text" @click.prevent="destroy(props.item)">
                <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
              </button>
            </div>
          </template>
        </data-column>
      </data-table>
    </box>
    <modal v-if="item" :show.sync="modal">
      <box>
        <div slot="header">
          {{ item.title || $t('newAlert') }}
        </div>
        <form @submit.prevent="submit">
          <div class="stepper">
            <div :class="{'stepper-item': true, 'stepper-done': stepper > 1, 'stepper-active': stepper === 1}" style="width: 50%" @click="stepper=1">
              <span class="steper-header">{{ $t('infomation') }}</span>
            </div>
            <div :class="{'stepper-item': true, 'stepper-done': stepper > 2, 'stepper-active': stepper === 2}" style="width: 50%" @click="stepper=2">
              <span class="steper-header">{{ $t('actions') }}</span>
            </div>
          </div>
          <div>
            <div v-show="stepper === 1">
              <div class="field">
                <label class="label">{{ $t('title') }}</label>
                <div class="control">
                  <input
                    v-model="item.title" class="input" type="text"
                    required
                  >
                </div>
              </div>

              <div class="field">
                <label class="label">{{ $t('description') }}</label>
                <div class="control">
                  <input v-model="item.description" class="input" type="text">
                </div>
              </div>

              <div class="field">
                <label class="label">{{ $t('query') }}</label>
                <div class="control">
                  <textarea
                    v-model="item.query" class="textarea" rows="2"
                    required
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">{{ $t('priority') }}</label>
                <p class="control is-expanded">
                  <span class="select" style="width: 100%">
                    <select v-model="item.priority" style="width: 100%">
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option selected="true" value="High">High</option>
                      <option value="Critical">Critical</option>
                    </select>
                  </span>
                </p>
              </div>

              <div class="field has-addons">
                <p class="control">
                  <span class="select">
                    <select v-model="conditionOperator">
                      <option disabled value="">{{ $t('operator') }}</option>
                      <option>≥</option>
                      <option>&gt;</option>
                      <option>≤</option>
                      <option>&lt;</option>
                      <option>=</option>
                      <option>≠</option>
                    </select>
                  </span>
                </p>
                <p class="control is-expanded">
                  <input
                    v-model="conditionValue" class="input" type="text"
                    :placeholder="$t('value')"
                  >
                </p>
              </div>
            </div>
            <div v-show="stepper === 2">
              <div class="field-line">
                <div class="field-2">
                  <input
                    id="first-alert" v-model="item.setting.mark_incident" type="checkbox"
                    name="first-alert" class="switch"
                  >
                  <label for="first-alert">{{ item.setting.mark_incident ? $t('markedIncidents') : $t('dontMarkedIncidents') }}</label>
                </div>
                <div class="field-2">
                  <input
                    id="remind" v-model="item.setting.remind" type="checkbox"
                    name="remind" class="switch" :disabled="!item.setting.mark_incident"
                  >
                  <label for="remind">{{ item.setting.remind ? $t('remind') : $t('dontRemind') }}</label>
                </div>
              </div>
              <div v-show="item.setting.mark_incident" class="field">
                <label class="label">{{ $t('maintainer') }}</label>
                <div class="field is-grouped">
                  <p class="control is-expanded">
                    <v-select
                      v-model="item.setting.maintainer" :options="userList" :multiple="true"
                    />
                  </p>
                </div>
              </div>
              <div v-show="item.setting.mark_incident && item.setting.remind" class="field-line">
                <div class="field-2">
                  <label class="label">{{ $t('deadline') }}</label>
                  <div class="field is-grouped">
                    <p class="control is-expanded">
                      <span class="select" style="width: 100%">
                        <select v-model="item.setting.deadline" style="width: 100%">
                          <option :value="30">30 Mins</option>
                          <option :value="60">1 Hour</option>
                          <option :value="180">3 Hours</option>
                          <option :value="360">6 Hours</option>
                          <option :value="720">12 Hours</option>
                          <option :value="1440">1 Day</option>
                          <option :value="4320">3 Day</option>
                          <option :value="10080">7 Day</option>
                        </select>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="field-2">
                  <label class="label">{{ $t('remindBeforeDeadline') }}</label>
                  <div class="field is-grouped">
                    <p class="control is-expanded">
                      <span class="select" style="width: 100%">
                        <select v-model="item.setting.offset" style="width: 100%">
                          <option selected="true" :value="15">15 Mins</option>
                          <option :value="30">30 Mins</option>
                          <option :value="60">1 Hour</option>
                          <option :value="180">3 Hours</option>
                          <option :value="360">6 Hours</option>
                          <option :value="720">12 Hours</option>
                          <option :value="1440">1 Day</option>
                        </select>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <tabs :theme="$_ui_theme_tabs">
                <tab :title="$t('email')" style="padding: 1em 0">
                  <div class="field">
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <input
                          v-model="email" class="input" type="text"
                          :placeholder="$t('email')"
                        >
                      </p>
                      <p class="control">
                        <a class="button is-info" @click.prevent="recipientAdd">{{ $t('add') }}</a>
                      </p>
                    </div>
                    <div v-for="(e, index) in item.recipients" :key="e" class="field is-grouped">
                      <p class="control is-expanded">
                        <input
                          class="input is-small" type="text" :placeholder="$t('email')"
                          :value="e"
                        >
                      </p>
                      <p class="control">
                        <a class="button is-small is-danger" @click.prevent="recipientRemove(index)"><octicon :icon="x" /></a>
                      </p>
                    </div>
                  </div>
                </tab>
                <tab :title="$t('tickets')" style="padding: 1em 0">
                  <div class="field">
                    <input
                      id="ticket-alert" v-model="item.ticket.enabled" type="checkbox"
                      name="ticket-alert" class="switch"
                    >
                    <label for="ticket-alert">{{ item.ticket.enabled ? $t('enabled') : $t('disabled') }}</label>
                  </div>
                  <div v-show="!item.setting.mark_incident && item.ticket.enabled" class="field">
                    <label class="label">{{ $t('maintainer') }}</label>
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <v-select
                          v-model="item.setting.maintainer" :options="userList" :multiple="true"
                        />
                      </p>
                    </div>
                  </div>
                  <div v-show="item.ticket.enabled" class="group-field">
                    <div class="field-auto" style="width: -webkit-fill-available;">
                      <label class="label">{{ $t('assignedTo') }}</label>
                      <div class="field is-grouped">
                        <p class="control is-expanded">
                          <v-select
                            v-model="item.ticket.assign" :options="userList" :multiple="true"
                          />
                        </p>
                      </div>
                    </div>
                    <div class="field-auto" style="margin-top: 32px;width: fit-content;height: 34px">
                      <button
                        id="deadline-btn" class="button" type="button"
                        :class="item.ticket.remind ? 'is-success' : 'is-default'"
                        title="Set Deadline" @click="item.ticket.remind = !item.ticket.remind"
                      >
                        <octicon :icon="clock" :scale="1.5" /><br><span />
                      </button>
                    </div>
                  </div>
                  <div v-show="item.ticket.remind" class="field-line">
                    <div class="field-2">
                      <label class="label">{{ $t('deadline') }}</label>
                      <div class="field is-grouped">
                        <p class="control is-expanded">
                          <span class="select" style="width: 100%">
                            <select v-model="item.ticket.deadline" style="width: 100%">
                              <option :value="30">30 Mins</option>
                              <option :value="60">1 Hour</option>
                              <option :value="180">3 Hours</option>
                              <option :value="360">6 Hours</option>
                              <option :value="720">12 Hours</option>
                              <option :value="1440">1 Day</option>
                              <option :value="4320">3 Day</option>
                              <option :value="10080">7 Day</option>
                            </select>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="field-2">
                      <label class="label">{{ $t('remindBeforeDeadline') }}</label>
                      <div class="field is-grouped">
                        <p class="control is-expanded">
                          <span class="select" style="width: 100%">
                            <select v-model="item.ticket.offset" style="width: 100%">
                              <option selected="true" :value="15">15 Mins</option>
                              <option :value="30">30 Mins</option>
                              <option :value="60">1 Hour</option>
                              <option :value="180">3 Hours</option>
                              <option :value="360">6 Hours</option>
                              <option :value="720">12 Hours</option>
                              <option :value="1440">1 Day</option>
                            </select>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </tab>
                <tab :title="$t('actions')" style="padding: 1em 0">
                  <div class="field">
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <v-select
                          v-model="item.actions" :options="actionName" :multiple="true"
                        />
                      </p>
                    </div>
                  </div>
                </tab>
                <tab :title="$t('playbook')" style="padding: 1em 0">
                  <div class="field">
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <v-select
                          v-model="item.playbooks" :options="playbookName" :multiple="true"
                        />
                      </p>
                    </div>
                  </div>
                </tab>
              </tabs>
            </div>
          </div>
          <!--
          <div class="field">
            <label class="label">{{ $t('status') }}</label>
            <input
              id="enabled" v-model="item.enabled" type="checkbox"
              name="enabled" class="switch"
            >
            <label for="enabled">{{ item.enabled ? $t('enabled') : $t('disabled') }}</label>
          </div>
          -->
          <div slot="footer" class="field is-grouped is-grouped-right" style="min-height: 80px; padding-top: 30px;">
            <div v-show="stepper > 1" class="control">
              <button type="button" class="button is-primary" @click.prevent="stepper--">
                {{ $t('previous') }}
              </button>
            </div>
            <div v-show="stepper < 2" class="control">
              <button type="button" class="button is-primary" @click.prevent="stepper++">
                {{ $t('next') }}
              </button>
            </div>
            <div v-show="stepper === 2" class="control">
              <button type="button" class="button is-link" @click="submit">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
  </section>
</template>
<script>
import { DataTable, DataColumn, Modal, Tabs, Tab } from '@cyradar/ui'
import { plus, x, pencil, clock } from 'octicons-vue'
import { format } from 'date-fns'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const regexOperator = /^(>|<|≥|≤|=|≠)(\d+)$/
const defaultCondition = {
  operator: '≥',
  value: '1',
  toString () {
    return `${this.operator}${this.value}`
  }
}

export default {
  components: { DataTable, DataColumn, Modal, Tabs, Tab, vSelect },
  data () {
    return {
      stepper: 1,
      modal: false,
      item: null,
      userNames: [],
      actionList: [],
      playbookList: [],
      email: '',
      apiChannels: []
    }
  },
  computed: {
    plus () {
      return plus
    },
    x () {
      return x
    },
    clock () {
      return clock
    },
    pencil () {
      return pencil
    },
    condition () {
      if (!this.item || !this.item.condition) {
        return defaultCondition
      }

      const matches = this.item.condition.match(regexOperator)
      if (!matches) {
        return defaultCondition
      }

      return {
        operator: matches[1],
        value: matches[2]
      }
    },
    conditionOperator: {
      get () {
        if (!this.condition || !this.condition.operator) {
          return ''
        }

        return this.condition.operator
      },
      set (val) {
        this.$set(this.item, 'condition', `${val}${this.condition.value}`)
      }
    },
    conditionValue: {
      get () {
        return this.condition.value
      },
      set (val) {
        this.$set(this.item, 'condition', `${this.condition.operator}${val}`)
      }
    },
    actionName () {
      return this.actionList.map(c => c.title)
    },
    playbookName () {
      return this.playbookList.map(c => c.title)
    },
    userList () {
      return this.userNames.map(c => c.email)
    },
    user () {
      return this.$store.getters['authentication/user']
    }
  },
  mounted () {
    this.getPlaybooks()
    this.getActions()
    this.loadUserName()

    if (!this.$route || !this.$route.params || !this.$route.params.query) {
      return
    }

    this.create()
    this.item.query = this.$route.params.query
  },
  methods: {
    format,
    closeModal () {
      this.modal = false
    },
    submit () {
      this.item.ticket.maintainer = this.item.setting.maintainer

      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    recipientAdd () {
      if (!this.item.recipients) {
        this.item.recipients = []
      }

      this.item.recipients.push(this.email)
      this.email = ''
    },
    recipientRemove (idx) {
      if (!this.item.recipients) {
        this.item.recipients = []
      }

      this.item.recipients.splice(idx, 1)
    },
    create () {
      this.item = {
        priority: 'High',
        enabled: true,
        setting: {
          maintainer: [this.user.id()],
          mark_incident: false,
          remind: false,
          deadline: 1440,
          offset: 30
        },
        ticket: {
          maintainer: [],
          enabled: false,
          assign: [],
          remind: false,
          deadline: 1440,
          offset: 30
        },
        actions: [],
        playbooks: [],
        condition: defaultCondition.toString()
      }
      this.modal = true
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
    },
    items (filter, order, pagination) {
      return this.$http.get('/api/v1/alerts', {
        params: {
          filter: filter.query,
          sort: order.by,
          order: order.order,
          page: pagination.page,
          limit: pagination.perPage
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        return body.data.data
      })
    },
    store () {
      return this.$http.post('/api/v1/alerts', this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    update () {
      return this.$http.patch(`/api/v1/alerts/${this.item.id}`, this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    destroy (item) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/alerts/${item.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    loadUserName () {
      return this.$http.get('/api/v1/users').then(body => {
        if (!body || !body.data) {
          return []
        }

        return body.data.data
      }).then(users => {
        this.userNames = users.items
      })
    },
    getPlaybooks () {
      return this.$http.get('/api/v1/playbooks', {
        params: {
          filter: '',
          sort: '_id',
          order: 'asc',
          page: 1,
          limit: 1000
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        this.playbookList = body.data.data.items
      })
    },
    getActions () {
      return this.$http.get('/api/v1/api-channels/names').then(body => {
        if (!body || !body.data) {
          return []
        }

        return body.data.data
      }).then(channels => {
        this.actionList = channels
      })
    }
  }
}
</script>
